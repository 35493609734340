html {
  font-size: 12px;
  font-family: $main-font;
  font-weight: 400;
  @include md {
    font-size: 14px;
  }
  @include lg {
    font-size: 16px;
  }
}
body, .app {
  min-height: 100vh;
}
body {
  &.sidebarIsActive {
    overflow: hidden;
  }
}
.app {
  max-width: 100%;
  overflow: hidden;
}
* {
  box-sizing: border-box;
  font-weight: 500;
  outline: none;
}
.main-title {
  color: $text-white;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2.25rem;
}
.title {
  color: $text-white;
  font-family: $main-font;
  font-size: 2.875rem;
  font-weight: 700;
  line-height: 3.375rem;
}
.wrapper {
  position: relative;
  width: 100%;
  max-width: calc(1175px + 30px);
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: border-box;
}
.background-dark {
  background-color: $blue-dark;
}
.background-bright {
  background-color: $text-white;
}
.simpleHover {
  transition: 0.1s;
  &:hover {
    opacity: 0.7;
  }
}

.preloader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 500;
  opacity: 1;
  -webkit-transition: opacity 1000ms linear;
  transition: opacity 1000ms linear;
}
.more {
  position: relative;
  opacity: 0.7;
  color: $blue-light;
  font-family: $main-font;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 1.688rem;
  // text-decoration: underline;
  margin-right: 1.25rem;
  border-bottom: 1px solid $blue-light;
  transition: 0.1s;
  cursor: pointer;
  &:after {
    position: absolute;
    display: block;
    content: '';
    width: 11px;
    height: 17px;
    bottom: -2px;
    right: -1.25rem;
    background: url('../assets/img/arrow-down.svg');
    background-position: center center;
    background-repeat: no-repeat;
  }
  &:hover {
    color: $problems-background-bright;
    border-color: transparent;
  }
}
.link {
  cursor: pointer;
  text-decoration: none;
}
.inputBox {
  position: relative;
  transition: 3s;
  margin-top: 1.5rem;
  &__label {
    position: absolute;
    transition: 0.1s;
    top: calc(50% - 0.56rem);
    left: 0;
    color: $blue-light;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.375rem;
    cursor: text;
    &.inFocus {
      font-size: 0.7rem;
      top: -1rem;
      // transform: scale(0.8);
    }
  }
  &__input {
    color: $text-white;
    font-family: $main-font;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.375rem;
    height: 40px;
    border: none;
    background: transparent;
    width: 100%;
    border-bottom: 1px solid $blue-light;
  }
  &__error {
    position: absolute;
    right: 0;
    bottom: calc(50% - 6px);
    font-size: 0.75rem;
    color: #f85a5a;
    height: 12px;
    display: none;
    transition: 0.2s;
  }
  &.error {
    .inputBox {
      &__input {
        color: #f85a5a;
      }
      &__error {
        display: block;
      }
    }
  }
}

.background__box {
  background-color: #fff;
  background-image: url('../assets/img/back_some.svg');
  background-position: 0% 15%;
  background-repeat: no-repeat;
}
.showInEffect {
  opacity: 0;
  transition: 1.5s all cubic-bezier(.17,.67,.83,.67);
}