.headerTokens {
  background: #1D3892;
  padding: 24px 0;
  &__container {
    display: flex;
    justify-content: space-between;
  }
  &__back {
    color: #fff;
    cursor: pointer;
    text-decoration: none;
  }
  &__mail {
    color: #fff;
    margin-right: 30px;
    letter-spacing: 0px;
  }
  &__logout {
    top: 24px;
    left: 1202px;
    width: 93px;
    height: 40px;
    background: rgba(255,255,255,0.1) 0% 0% no-repeat padding-box;
    border-radius: 20px;
    cursor: pointer;
    border: none;
    color: #fff;
    &:hover {
      background: rgba(255,255,255,0.4) 0% 0% no-repeat padding-box;
    }
  }
  .left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}