.footer {
  position: relative;
  &__container {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    padding: 2.5rem 0;
    flex-direction: column-reverse;

    align-items: center;
  }
  &__item {
    &-left {
      display: flex;
      flex-direction: column;
      
      justify-content: space-between;

    }
  }
  &__copyright {
    color: $blue-light;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.375rem;
    text-align: center;
  }
  &__support {
    display: block;
  }
  &__link {
    display: inline-block;
    color: $text-white;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.375rem;
    text-decoration: none;
    border-bottom: 1px solid $text-white;
    margin: 0 10px 10px 10px;
    &:nth-of-type(2) {
      margin-top: 8px;
    }
    &:hover {
      color: $blue-acid;
      border-bottom-color: $blue-acid;
    }
  }
  &__pic {
    width: 100%;
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    img {
      width: 100%;
    }
  }
  &__boxHelper {
    overflow: hidden; 
  }
  .wrapper {
    position: relative;
    z-index: 3;
  }
  @include rwd(600) {
    &__container {
      flex-direction: row;
      align-items: flex-start;
    }
    &__item {
      &-left {
        flex-direction: row;
        width: 70%;
      }
    }
    &__support {
      display: flex;
      flex-direction: column;
    }
    &__copyright {
      text-align: left;
    }
  }
}