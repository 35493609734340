.oton {
  position: relative;
  &__back {
    video {
      width: 100%;
      display: none;
      @include md {
        display: block;
      }
    }
  }
  &__container {
    position: absolute;
    width: 100%;
    left: 0;
    top: calc(50% - 40px);
    @include sm {
      top: calc(50% - 50px);
    }
    @include md {
      top: calc(50% - 80px);
    }
    @include lg {
      top: calc(50% - 120px);
    }
    @include xl {
      top: calc(50% - 170px);
    }
  }
  &__shadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50%;
    display: block;
    background-image: linear-gradient(180deg, #0e208c 0%, rgba(14, 32, 140, 0) 100%);
  }
  &__shadow-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    display: block;
    background-image: linear-gradient(0deg, $blue-dark 0%, rgba(31, 58, 147, 0) 100%);
  }
  &__letters {
    display: flex;
    justify-content: space-between;
  }
  &__letter {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: 0 1 auto;
    &:not(:last-of-type) {
      padding-right: 3%;
    }
    @include md {
      padding-right: 0;
    }
    span {
      color: $text-white;
      font-size: 1.4rem;
      margin-top: 1.6rem;
      font-weight: 400;
      opacity: 0.6;
      @include md {
        margin-top: 3.6rem;
      }
    }
    img {
      width: 100%;
    }
  }
  &__mobileBanner {
    width: 100%;
    img {
      width: 100%;
    }
    @include md {
      display: none;
    }
  }
}