//colors
$blue-dark: #1f3a93;
$blue-light: #5fd2ff;
$text-white: #ffffff;
$text-black: #666666;
$blue-acid: #31ffce;
$black: #000000;
$blue-dark-2: #2a459b;
$problems-background-dark: #446cb3;
$problems-background-bright: #2c91da;

//fonts
$main-font: "Graphik";
