.counter {
  display: flex;
  .item {
    font-size: 64px;
    color: #fff;
  }
  .value {
    font-weight: 600;
  }
  .dayName {
    font-size: 24px;
  }
}
