.marketplace {
  background: $text-white;
  padding-top: 6.8rem;
  padding-bottom: 6.2rem;
  &__container {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    @include lg {
      flex-direction: row;
      .marketplace__item {
        &:nth-of-type(2) {
          margin-top: -140px;
        }
      }
    }
  }
  &__title {
    color: $black;
  }
  &__pic {
    width: 100%;
    margin-top: 4.5rem;
  }
  &__item {
    &:nth-of-type(2) {
      // flex: 0;
      flex: 0 0 100%;
      max-width: 696px;
    }
  }
  &__textMain, &__textSecondary {
    max-width: 370px;
  } 
  &__textMain {
    color: $text-black;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 2.25rem;
  }
  &__textSecondary {
    color: $blue-dark;
    font-family: $main-font;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.75rem;
  }
}