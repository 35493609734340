.doubleBanner {
  margin-bottom: 20px;
  position: relative;

  width: calc(320px * 3 + 40px);

  &.kyc {
    background-color: #1c2e60;
  }
  &__pic {
    position: absolute;
    width: 100%;
    height: 100%;

    &.kyc {
      left: -4px;
    }
  }

  &__box {
    position: relative;
    z-index: 20;
    display: flex;
    align-items: stretch;
    min-height: 95px;
  }

  &__x2 {
    position: absolute;

    top: -7px;
    width: 80px;
    height: 109px;
  }

  &__inner {
    position: relative;
    // margin-left: 35px;
    &:first-of-type {
      // width: 80px;
      // height: 109px;
      padding-top: 16px;
      padding-left: 20px;
      line-height: 32px;
    }
    &:last-of-type {
      // margin-left: 25px;
      display: flex;
      align-items: center;
      flex-grow: 1;
      justify-content: center;
    }
    &.kyc {
      justify-content: flex-end;
    }
  }

  &__text {
    font-size: 26px;
    max-width: 240px;
    color: #fff;
    font-weight: bold;

    &.kyc {
      max-width: 340px;
    }
  }
  .toKyc {
    padding: 6px 14px 7px 14px;
    border-radius: 20px;
    background-color: #ffd13a;
    color: #1c2e60;
    cursor: pointer;
    font-size: 20px;
    text-decoration: none;
    margin-right: 32px;
  }
}
