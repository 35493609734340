
.footer-tokens {
  &.footer {
    background: #fff;
    padding-top: 1px;
  }
  .footer {
    
    &__container {
      border-top: 4px solid #F3F5F9;
    }
    &__copyright {
      color: #1C2E60;
    }
    &__link {
      color: #3259D8;
      border-bottom-color: #3259D8;
      &:hover {
        color: $blue-acid;
        border-bottom-color: $blue-acid;
      }
    }
  }
}