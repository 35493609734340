.sidebar {
  // position: absolute;
  // top: 0;
  // left: 0;
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #0097d1;
    opacity: 0.7;
    transition: 0.2s;
    display: none;
    transition: 0.2s;
    z-index: 30;
    &.active {
      display: block;
    }
  }
  &__container {
    position: fixed;
    width: 100vh;
    width: 280px;
    top: 0;
    left: -100%;
    transition: 0.3s;
    z-index: 400;
    height: 100vh;
    &.active {
      background: #fff;
      left: 0;
    }
  }
  &__logo {
    position: absolute;
    top: 30px;
    width: 100px;
    left: calc(50% - 50px);
  }
  &__nav {
    display: flex;
    flex-direction: column;
    margin-top: 50%;
    padding-left: 25px;
    div {
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }
    @media (orientation: landscape) {
      margin-top: 40%;
    }
  }
  &__socials {
    position: absolute;
    bottom: 90px;
    left: calc(50% - 55px);
    flex-direction: row;
    .banner__social {
      &:not(:last-of-type) {
        margin-right: 10px;
      }
    }
    @media (orientation: landscape) {
      bottom: 20px;
    }
  }
  .nav__item {
    color: $black;
  }
}