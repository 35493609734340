.unite {
  padding-top: 14rem;
  // background-image: linear-gradient(0deg, #0e208c 0%, rgba(14, 32, 140, 0) 100%);
  background: #0e208c;
  &__container {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;
    @include md {
      flex-direction: row;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      margin-top: 0;
      max-width: 470px;
      color: $text-white;
      font-size: 1.5rem;
      line-height: 2.25rem;
    }
    img {
      width: 100%;
    }
  }
  &__moreBox {
    margin-bottom: 70px;
  }
}