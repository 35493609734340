.scenaries {
  padding-bottom: 9.3rem;
  &__tabs {
    display: flex;
    flex-direction: column;
  }
  &__tab {
    max-width: 320px;
    border-bottom: 2px solid $blue-light;
    color: $blue-light;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.575rem;
    cursor: pointer;
    padding-bottom: 0.8rem;
    padding-right: 3%;
    &.active {
      border-bottom: 2px solid $blue-acid;
      color: $blue-acid;
    }
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
    &:hover {
      color: $text-white;
      border-bottom-color: $text-white;
    }
  }
  &__panes {
    margin-top: 3.7rem;
  }
  &__pane {
    display: none;
    transition: 0.3s;
    justify-content: space-between;
    flex-direction: column;
    &.active {
      display: flex;
    }
  }
  &__step {
    position: relative;
    background-color: $blue-dark-2;
    border-radius: 1.5rem;
    color: $text-white;
    font-size: 1rem;
    font-weight: 500;
    // flex: 0 0 calc(33.3% - 1.4rem);
    flex-grow: 1;
    flex-basis: 0;
    padding: 2.5rem 1.4rem 6.8rem 2.5rem;
    margin-bottom: 40px;
    &:not(:last-of-type) {
      margin-right: 1.8rem;
    }
    &:after {
      position: absolute;
      display: block;
      color: $blue-acid;
      font-size: 8.1rem;
      line-height: 5.3rem;
      font-weight: 900;
      left: 2.5rem;
      bottom: -1.25rem;
    }
    &:nth-of-type(1) {
      &:after {
        content: '1';
      }
    }
    &:nth-of-type(2) {
      &:after {
        content: '2';
      }
    }
    &:nth-of-type(3) {
      &:after {
        content: '3';
      }
    }
    &:nth-of-type(4) {
      &:after {
        content: '4';
      }
    }
  }
  @include md {
    &__tabs {
      flex-direction: row;
    }
    &__tab {
      flex: 0 0 33.3%;
      max-width: none;
      margin-bottom: 0!important;
    }
    &__pane {
      flex-direction: row;
    }
    &__step {
      margin-bottom: 0px;
    }
  }
}