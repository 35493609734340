.buyTokensButton {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  background: rgba(255, 255, 255, 0.1) 0% 0% no-repeat padding-box;
  border-radius: 20px;
  border: none;
  padding: 0 24px 0 46px;
  cursor: pointer;
  &__pic {
    position: absolute;
    width: 28px;
    height: 28px;
    top: 6px;
    left: 6px;
  }
  &__text {
    font-size: 0.875rem;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
  }
  &:hover {
    background: rgba(255, 255, 255, 0.4) 0% 0% no-repeat padding-box;
  }
}