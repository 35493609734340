.buyTokens {
  min-height: 100vh;
  padding-bottom: 80px;
  background: #fff;
  padding-top: 50px;
  * {
    font-family: "PT Sans", sans-serif;
  }
  .TitleBox {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    // ${getMedia(
    //   768,
    //   css`
    //     margin-bottom: 25px;
    //   `,
    // )};
  }
  .Title {
    text-align: left;
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0px;
    color: #1c2e60;
    opacity: 1;
  }
  .Container {
    display: flex;
    flex-wrap: wrap;
  }
  .ItemBox {
    margin-bottom: 10px;
    &:not(:last-child) {
      margin-right: 20px;
    }
    /* ${getMedia(
      768,
      css`
        width: 25%;
      `,
    )}; */
  }
  .OtonTokenItem {
    max-width: 320px;
    min-width: 300px;
    height: 100%;
    width: 100%;
    background-color: #f2f7fd;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .inactive {
      cursor: default;
      opacity: 0.6;
    }
  }
  .ItemHeader {
    width: 100%;
    color: #ffffff;
    font-size: 20px;
    font-weight: 700;
    line-height: 44px;
    padding: 0 15px;
    background-color: #1c2e60;
    border-radius: 2px 2px 0 0;
  }
  .Body {
    width: 100%;
    height: calc(100% - 44px - 72px);
    background-color: #f2f7fd;
    padding: 8px 15px 13px;
    display: flex;
    flex-direction: column;
    font-size: 15px;
  }
  .BuyVipButton {
    display: flex;
    margin-top: 5px;
  }
  .PackagesInfoBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
  }
  .PackagesInfoRatesBox {
    margin-left: -5px;
    margin-right: 20px;
  }
  .PackagesInfoRate {
    width: 143px;
    height: 23px;
    line-height: 23px;
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    padding: 0 8px 0 5px;

    &.active {
      font-weight: bold;
      background-color: #f2dc6b;
    }
  }
  .PackagesInfoText {
    opacity: 0.6;
    color: #1c2e60;
    font-size: 12px;
    font-weight: 400;
  }
  .BodyPriceHeader {
    color: #1c2e60;
    font-size: 20px;
    font-weight: 700;
    margin-top: auto;
    width: 100%;
  }
  .BodyPriceFooter {
    color: #1c2e60;
    font-size: 15px;
    width: 100%;
  }
  .Footer {
    width: 100%;
    background-color: rgba(#1d3892, 0.1);
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    &CheckBox {
      margin-top: 14px;
    }
  }
  .FooterButton {
    margin-right: 20px;
    .Button {
      opacity: 0.5;
      cursor: default;
      &.active {
        cursor: pointer;
        opacity: 1;
      }
    }
  }
  // .FooterPriceBox {}
  .FooterPriceEur {
    font-size: 15px;
    &.price {
      font-weight: 700;
    }

    .old {
      position: relative;

      &:after {
        position: absolute;
        display: block;
        content: "";
        height: 2px;
        width: 75%;
        transform: rotate(30deg);
        background: #ff006f;
        top: 50%;
        left: 27%;
      }
    }
    .new {
      color: #ff006f;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .FooterPriceBtc {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 15px;
  }
  .Button {
    display: inline-block;
    padding: 7px 20px;
    cursor: pointer;
    color: #ffffff;
    font-family: "PT Sans";
    font-size: 20px;
    box-shadow: 0 3px 6px rgba(81, 39, 62, 0.04);
    background-color: #2ecc34;
    text-decoration: none;
    border: 0;

    &:hover,
    &:focus {
      text-decoration: none;
      outline: none;
    }
    &.disabled {
      cursor: default;
    }
  }
  .Content {
    max-width: 646px;
    min-width: 300px;
    height: 100%;
    width: 100%;
    background-color: #f2f7fd;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .BlockContainer {
    &.Buyer {
      margin-top: 28px;
    }
  }
  .TokensInfoBox {
    border-left: 2px solid #1d3892;
  }
  .CountDownBox {
    margin-top: 23px;
    margin-bottom: 20px;
    &.Flexed {
      display: flex;
      @media (max-width: 660px) {
        display: block;
      }
    }
    &__ButtonBox {
      margin-top: 12px;
      .Button {
        opacity: 0.5;
        cursor: default;
        &.active {
          cursor: pointer;
          opacity: 1;
        }
      }
      @media (max-width: 460px) {
        margin-bottom: 10px;
      }
    }
    &__Content {
      display: flex;
      flex: 1;
      @media (max-width: 460px) {
        display: block;
      }
    }
    &__Item {
      &:first-of-type {
        margin-right: 32px;
      }
    }
    &__Input {
      width: 200px;
      height: 36px;
      border: 1px solid rgba(28, 46, 96, 0.25);
      font-size: 20px;
      color: #1c2e60;
      background: transparent;
      padding-left: 10px;
    }
    &__Title {
      letter-spacing: 0px;
      color: #1c2e60;
      opacity: 0.6;
      font-size: 15px;
      margin-bottom: 8px;
      &:not(:nth-of-type(2)) {
        margin-top: 12px;
      }
    }
    &__Balance {
      font-size: 20px;
      letter-spacing: 0px;
      color: #1c2e60;
      opacity: 1;
    }
    &__Slider {
      position: relative;
      height: 16px;
      width: 100%;
      background: rgba(#1d3892, 0.1) 0% 0% no-repeat padding-box;
    }
    &__FillingLine {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      background: #2ecc34 0% 0% no-repeat padding-box;
    }
    &__SliderInfo {
      display: flex;
      justify-content: space-between;
    }
    &__SliderTitle {
      font-size: 15px;
      color: #1c2e60;
      &.right {
        text-align: right;
      }
    }
    &__SliderValue {
      font-size: 25px;
      color: #1c2e60;
      margin: 4px 0 8px 0;
      &.right {
        text-align: right;
      }
    }
    &__Terms {
      margin-top: 14px;
    }
  }
  .TokensInfoBox {
    max-width: 230px;
    padding-left: 15px;
    color: #1c2e60;
    p {
      margin: 0;
      color: #1c2e60;
    }
  }
  .Balance {
    display: flex;
    margin-bottom: 24px;
    @media (max-width: 660px) {
      display: block;
    }
    &__Box {
      &:first-of-type {
        margin-right: 32px;
        @media (max-width: 660px) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
    }
    &__Title {
      font-size: 15px;
      letter-spacing: 0px;
      color: #1c2e60;
      opacity: 0.6;
    }
    &__Value {
      font-size: 32px;
      letter-spacing: 0px;
      color: #1c2e60;
      opacity: 1;
    }
  }
}
.Checkbox {
  position: realtive;
  &__Input {
    visibility: hidden;
    position: absolute;
    left: -2000px;
    &:checked {
      & ~ .Checkbox__Label {
        &:after {
          display: block;
        }
      }
    }
  }
  &__Label {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    a {
      color: #3259d8;
      &:visited,
      &:active {
        color: #3259d8;
      }
    }
    &:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 14px;
      height: 14px;
      border: 1px solid #1c2e60;
    }
    &:after {
      display: none;
      position: absolute;
      content: "";
      top: 4.3px;
      left: 4.3px;
      width: 8px;
      height: 8px;
      background: #2ecc34 0% 0% no-repeat padding-box;
    }
  }
}
.authButton {
  display: block;
  background: #1c2e60;
  color: #fff;
  height: 40px;
  width: 200px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 auto;

  &__text {
    margin-left: 10px;
  }

  &:hover {
    opacity: 0.8;
  }
}
