.participants {
  padding-top: 3.3rem;
  margin-bottom: 4rem;
  &__list {
    display: flex;
    flex-direction: column;
    
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 0 0 33.3%;
    
  }
  &__pic {
    display: flex;
    align-items: flex-end;
    max-width: 220px;
    
    margin-bottom: 2.6rem;;
    img {
      width: 100%;
    }
  }
  &__name {
    color: $blue-acid;
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 1.513rem;
  }
  &__text {
    color: $blue-light;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.575rem;
    max-width: 100%;
  }
  @include md {
    &__list {
      flex-direction: row;
      justify-content: space-between;
    }
    &__pic {
      max-width: 220px;
      height: 240px;
    }
    &__text {
      padding-right: 20px;
      max-width: 370px;
    }
    &__item {
      align-items: flex-start;
      &:not(:nth-of-type(3)) {
        .participants__text {
          max-width: 270px;
        }
      }
    }
  }
  @include lg {
    &__pic {
      max-width: 270px;
      height: 284px;
    }
    &__text {
      padding-right: 0px;
    }
  }
}