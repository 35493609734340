.twoModal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  &__layout {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #1D3892;
    opacity: 0.9;
  }
  &__container {
    position: absolute;
    top: 20%;
    left: calc(50% - 140px);
    width: 280px;
    z-index: 300;
    background: #fff;
    padding: 30px 20px;
  }
  &__title {
    text-align: center;
    font-size: 20px;
    color: #1D3892;
  }
  &__text {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  &__input {
    width: 100%;
    border: 1px solid #1D3892;
    border-radius: 2px;
    height: 30px;
    margin-bottom: 10px;
    padding-left: 15px;
  }
}