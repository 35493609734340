.header {
  position: absolute;
  width: 100%;
  z-index: 2;
  display: block;
  @include lg {
    display: block;
  }
  &__container {
    padding-top: 4.2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__socialLink {
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
  &__applicationButton {
    display: block;
    width: 9.5rem;
    height: 2.5rem;
    border-radius: 1.25rem;
    background-color: rgba(255, 255, 255, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    color: $text-white;
    font-size: 0.875rem;
    cursor: pointer;
    &:hover {
      background-color: rgba(255, 255, 255, 0.4);
    }
  }
  &__application {
    display: none;
    @include md {
      display: block;
    }
  }
  &__socials {
    display: none;
    @include md {
      display: block;
    }
  }
  .right {
    display: flex;
  }
}

.nav {
  display: none;
  align-items: center;
  justify-content: space-between;
  // flex: 1 0;
  max-width: 700px;
  &__item {
    color: $text-white;
    font-size: 0.875rem;
    cursor: pointer;
    margin: 0 15px;
  }
  @include md {
    display: flex;
  }
}

.switcher {
  
  display: flex;
  align-items: center;
  cursor: pointer;
  &__box {
    position: relative;
    width: 34px;
    height: 18px;
    border-radius: 100px;
    border: 1px solid #b3b7c8;
    margin: 0 8px;
  }
  &__lang {
    opacity: 0.5;
    font-size: 0.875rem;
    color: $text-white;
    &.active {
      opacity: 1;
    }
  }
  &__dot {
    position: absolute;
    background-color: #0097d1;
    width: 10px;
    height: 10px;
    left: 4px;
    top: 3px;
    border-radius: 50%;
    &.eng {
      left: 18px;
    }
  }
}
.sandwitch {
  position: absolute;
  width: 28px;
  height: 24px;
  right: 0;
  top: 47px;
  right: 20px;
  display: block;
  cursor: pointer;
  span {
    position: absolute;
    height: 4px;
    width: 100%;
    background: #0097d1;
    &:nth-of-type(2) {
      top: calc(50% - 2px);
    }
    &:nth-of-type(3) {
      top: calc(100% - 4px);
    }
  }
  @include md {
    display: none;
  }
}