.problems {
  position: relative;
  margin-bottom: 7.5rem;
  &__container {

  }
  &__titleBox {
    display: flex;
    justify-content: flex-end;
  }
  &__box {
    max-width: 570px;
    margin-bottom: 100px;
    margin-top: 40px;
  }
  &__title {

  }
  &__back {
    position: absolute;
    right: calc(50% + 20px);
    top: 0;
  }
  &__text {
    color: $text-white;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2.25rem;
  }
}
.problem {
  background-color: $problems-background-dark;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  &:nth-of-type(odd) {
    background-color: $problems-background-bright;
  }
  &__innerBlock {
    padding-bottom: 20px;
  }
  &__name {
    margin-top: 16px;
    color: $blue-acid;
    font-family: $main-font;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.75rem;
  }
  &__solution {
    color: $text-white;
    font-family: $main-font;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;
  }
  &__earlier {
    color: $text-white;
    font-family: $main-font;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.375rem;


  }
  &__earlierTitle {
    color: $text-white;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.375rem;
  }
  @include md {
    flex-direction: row;
    &__innerBlock {
      padding-bottom: 0;
      &:nth-of-type(1) {
        flex: 0 0 23%;
        padding-right: 0;
      }
      &:nth-of-type(2) {
        flex: 0 0 45%;
        padding-left: 1.1rem;
        padding-right: 1.1rem;
      }
      &:nth-of-type(3) {
        padding-left: 1.1rem;
      }
    }
  }
}