.roadmap {
  padding-top: 5.3rem;
  &__title {
    color: $black;
  }
  &__map {
    display: flex;
    flex-direction: column;
  }
  &__name {
    color: $blue-dark;
    font-family: $main-font;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 3.375rem;
    max-height: 3.37rem;
    .slash {
      color: $blue-acid;
      font-size: 1.375rem;
    }
  }
  &__list {
    max-width: 360px;
    padding-right: 20px;
  }
  &__stage {
    flex: 1 0;
    &:last-of-type {
      .roadmap__line {
        overflow: visible;
        // box-shadow: inset -20px 0px 59px -10px rgba(255,255,255,1);
        .line {
          &:after {
            position: absolute;
            top: -10px;
            right: 0;
            width: 50%;
            height: 20px;
            content: '';
            box-shadow: inset -140px 0px 100px -57px rgba(255,255,255,1);
          }
        }
      }
    }
  }
  &__item {
    position: relative;
    color: $black;
    font-family: $main-font;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2rem;
    padding-left: 10px;
    margin-bottom: 0.6rem;
    &:before {
      position: absolute;
      content: '';
      width: 4px;
      height: 4px;
      top: 0.42rem;
      left: 0;
      background-color: #acd7ff;
      border-radius: 50%;
    }
  }
  &__styled {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  &__line {
    position: relative;
    height: 30px;
    margin-left: 14px;
    overflow: hidden;
    width: calc(100% - 75px);
    
    .line {
      position: absolute;
      top: calc(50% - 2px);
    }
  }
  &__preLine {
    position: absolute;
    width: 30px;
    height: 4px;
    top: calc(50% - 2px);
    left: -40px;
    overflow: hidden;
    &:before {
      position: absolute;
      top: -10px;
      left: 0;
      width: 50%;
      height: 20px;
      content: '';
      z-index: 1;
      opacity: 0.8;
      box-shadow: inset 134px 0px 100px -57px rgba(255,255,255,1);
    }
    .line {
      position: absolute;
      top: 0;
      right: 0;
      
    }
    // overflow: hidden;
  }
  @include rwd(590) {
    &__map {
      flex-direction: row;
    }
  }
}
.line {
  display: flex;
  &__dot {
    width: 4px;
    height: 4px;
    background: $blue-acid;
    border-radius: 50%;
    &:not(:last-of-type) {
      margin-right: 4px;
    } 
  }
}