.whitepaper {
  padding-top: 2rem;
  padding-bottom: 60px;
  
  &__box {
    display: flex;

    flex-direction: column-reverse;
  }
  &__title {
    color: $black;
  }
  &__text {
    max-width: 470px;
    color: $text-black;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2.25rem;
  }
  &__links {
    margin-top: 3rem;
    .box {
      &:not(:nth-of-type(1)) {
        margin-top: 1.7rem;
      }
    }
  }
  &__link {
    color: $blue-dark;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.75rem;
    border-bottom-width: 2px;
    border-bottom-color:  $blue-dark;
    text-decoration: none;
    &:after {
      bottom: 0;
      background-image: url('../assets/img/arrow_down-1.svg');
    }
  }
  &__size {
    color: $text-black;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.575rem;
  }
  &__pic {
    max-width: 300px;
    margin: 0 auto;
    img {
      width: 100%;
    }
  }
  @include md {
    &__pic {
      max-width: 400px;
      margin-top: -80px;
    }
  }
  @include lg {
    &__pic {
      max-width: none;
    }
  }
  @include rwd(600) {
    padding-bottom: 30px;
    &__pic {
      margin: 0;
    }
    &__box {
      flex-direction: row;
    }
  }
}