$screen-sm-min: 480px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1170px;

@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@mixin rwd($screen) {
  @media (min-width: $screen+'px') {
    @content;
  }
}