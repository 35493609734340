.banner {
  padding-top: 10rem;
  background-image: linear-gradient(0deg, #0e208c 0%, rgba(14, 32, 140, 0) 100%);
  &__container {
    position: relative;
    z-index: 2;
  }
  &__title {
    max-width: 376px;
    margin-top: -10%;
    font-weight: 500;
    line-height: 2.25rem;
  }
  &__logo {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    min-height: 453px;
    img {
      position: relative;
      max-width: 100%;
      z-index: 3;
    }
  }
  &__back {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    text-align: right;
    img {
      width: 100%;
    }
  }
  &__box {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include md {
      flex-direction: row;
    }
  }
  &__more {

  }
}
.logo {
  &__animateBlock {
    position: absolute;
    z-index: 2;
    width: 453px;
    height: 453px;
    left: calc(50% - 195px);
    top: calc(50% - 215px);
    transform: scale(0.5);
    @include sm {
      left: calc(50% - 180px);
      transform: scale(0.8);
    }
    @include md {
      transform: scale(1);
      left: calc(50% - 175px);
      top: calc(50% - 210px);
    }
  }
  &__item {
    position: absolute;
    transition: 0.1s;
    // left: 50%;
    // top: 50%;
    
    &-1 {
      left: calc(50% - 25px);
      top: calc(50% - 28px);
    }
    &-2 {
      left: calc(50% - 71px);
      top: calc(50% - 74px);
    }
    &-3 {
      left: calc(50% - 107px);
      top: calc(50% - 109px);
    }
    &-4 {
      left: calc(50% - 139px);
      top: calc(50% - 141.5px);
    }
    &-5 {
      left: calc(50% - 169px);
      top: calc(50% - 170px);
    }
    &-6 {
      left: calc(50% - 197.5px);
      top: calc(50% - 198px);
    }
    &-7 {
      left: calc(50% - 224px);
      top: calc(50% - 226px);
    }
  }
}