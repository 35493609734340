
.request {
  padding-top: 5.3rem;
  &__box {
    max-width: 570px;
    margin: 0 auto;
  }
  &__title {
    text-align: center;
  }
  &__tabs {
    display: flex;
    width: 90%;
    margin: 0 auto;
  }
  &__tab {
    flex: 1 0;
    color: $blue-light;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.575rem;
    border-bottom: 1px solid $blue-light;
    cursor: pointer;
    text-align: center;
    &.active {
      color: $blue-acid;
      border-color: $blue-acid;
    }
    &:hover {
      color: $text-white;
      border-bottom-color: $text-white;
    }
  }
  &__panes {
    position: relative;
  }
  &__pane {
    display: none;
    &.active {
      display: block;
    }
  }
  &__someText {
    color: $text-white;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2.1rem;
    text-align: center;
    padding: 1rem 0;
  }
  &__submit {
    height: 4.37rem;
    border-radius: 2.188rem;
    background-color: $blue-acid;
    color: $black;
    border: none;
    width: 100%;
    margin: 40px 0;
    cursor: pointer;
    transition: 0.1s;
    &:hover {
      background-color: #2ee8bb;
    }
  }
  &__message {
    color: $blue-acid;
    font-size: 2rem;
    text-align: center;
    visibility: hidden;
    &.active {
      visibility: visible;
    }
  }
  .wrapper {
    z-index: 3;
  }
  &__response {
    display: none;
    position: absolute;
    width: 100%;
    top: 50px;
    background: $blue-dark-2;
    z-index: 10;
    padding: 20px;
    text-align: center;
    border-radius: 40px;
    height: calc(100% - 50px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &.active {
      display: flex;
    }
    .request__submit {
      max-width: 50%;
      margin: 0 auto;
    }
  }
  &__responseText {
    color: $text-white;
    text-align: center;
    max-width: 60%;
  }
}