.payments {
  background: $text-white;
  padding-top: 1px;
  padding-bottom: 5rem;
  &__text {
    color: $text-black;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2.25rem;
  }
  &__title {
    color: $black;
  }
  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2.5rem;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 300px;
    margin-bottom: 30px;
    
  }
  &__name {
    margin-bottom: 1.25rem;
    color: $black;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1rem;
  }
  &__pic {
    flex: 1;
    display: flex;
    align-items: center;
    img {
      max-width: 100%;
    }
  }
  @include md {
    &__list {
      flex-direction: row;
      align-items: unset;
    }
    &__item {
      margin-bottom: 0px;
      align-items: flex-start;
      &:not(:nth-of-type(1)) {
        margin-right: 13%;
      }
    }
  }
}